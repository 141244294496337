import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivacyRoutingModule } from './privacy-routing.module';
import { PrivacyEnComponent } from './privacy-en/privacy-en.component';
import { PrivacyDeComponent } from './privacy-de/privacy-de.component';
import { PrivacyItComponent } from './privacy-it/privacy-it.component';
import { HeadersModule } from '../headers/headers.module';

@NgModule({
  imports: [
    CommonModule,
    PrivacyRoutingModule,
    HeadersModule
  ],
  declarations: [PrivacyEnComponent, PrivacyDeComponent, PrivacyItComponent]
})
export class PrivacyModule { }
