import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { CognitoUser } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class FurnitureService {

  private furnituresListUrl = environment.endpoint.rootApi + environment.endpoint.furnitures.list;
  private furnituresUpsertUrl = environment.endpoint.rootApi + environment.endpoint.furnitures.upsert;
  private furnituresRegisterUrl = environment.endpoint.rootApi + environment.endpoint.furnitures.register;
  private furnituresDetailUrl = environment.endpoint.rootApi + environment.endpoint.furnitures.detail;
  private furnituresDeleteUrl = environment.endpoint.rootApi + environment.endpoint.furnitures.delete;

  constructor(
    private http: HttpClient,
    private appService: AppService
  ) { }

  getFurnitures(user, lastKey?: any, filters?: any): Observable<any> {
    let request = this.furnituresListUrl + "?user="+ user;
    if (lastKey) {
      request = request + "&lastKey=" + encodeURI(JSON.stringify(lastKey));
    }
    // Filters logic
    if (filters) {
      if (filters.brand) {
        request = request + "&brand=" + filters.brand;
      }
      if (filters.model) {
        request = request + "&model=" + filters.model;
      }
      if (filters.version) {
        request = request + "&version=" + filters.version;
      }
      if (filters.owner) {
        request = request + "&owner=" + filters.owner;
      }
    }
    
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(request, { headers: headers }).toPromise();
        }
      )); 
  }

  getFurnitureByCode(code): Observable<any> {
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(this.furnituresRegisterUrl + code, { headers: headers }).toPromise();
        }
      ));
  }

  upsertFurniture(body): Observable<any> {
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.put(this.furnituresUpsertUrl, JSON.stringify(body), { headers: headers }).toPromise();
        }
      ));
  }

  deleteFurniture(code, us): Observable<any> {
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.delete(this.furnituresDeleteUrl + code + "?user=" + us, { headers: headers }).toPromise();
        }
      ));
  }
}
