import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-distribution-details-modal',
  templateUrl: './distribution-details-modal.component.html',
  styleUrls: ['./distribution-details-modal.component.css']
})
export class DistributionDetailsModalComponent implements OnInit {

  constructor(
    private modal: NgbActiveModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  @Input() private distribution: any;

  private distributionDetails = {
    type: '',
    brand: '',
    model: '',
    version: '',
    key: ''
  };

  getDetails() {
    let details = this.distribution.details;

    this.distributionDetails.type = details.type;
    this.distributionDetails.brand = details.brand;
    this.distributionDetails.model = details.model;
    this.distributionDetails.version = details.version;
    this.distributionDetails.key = details.key;
  }
}
