import { Component, OnInit, Input } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile-confirm',
  templateUrl: './edit-profile-confirm.component.html',
  styleUrls: ['./edit-profile-confirm.component.css']
})
export class EditProfileConfirmComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  private verificationCode: string;

  verifyUser() {
    if (this.verificationCode) {
      this.amplifyService.auth()
        .verifyCurrentUserAttributeSubmit("email", this.verificationCode)
          .then(() => {
            this.toastr.success(this.translateService.instant("users.edit-profile.messages.success.email-confirm"), this.translateService.instant("users.edit-profile.messages.success.title"), {
              timeOut: 2500
            });
            this.modal.close();
          })
          .catch(() => {
            this.toastr.error(this.translateService.instant("users.edit-profile.messages.errors.verification-code"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
              timeOut: 2500
            });
          })
    }
    
  }
}
