import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss']
})
export class UserDetailsModalComponent implements OnInit {

  @Input() private user: any;
  
  constructor(
    private modal: NgbActiveModal,
    private amplifyService: AmplifyService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.user.name) {
      this.user.name = '';
    } 
    if (!this.user.surname) {
      this.user.surname = '';
    }
    if (!this.user.isAdmin) {
      this.user.isAdmin = false;
    }
    // Check is me
    if (this.user.isAdmin) {
      this.amplifyService.auth().currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          this.user.isMe = user.getUsername();
        });
    }
  }

  updateProfileInfo() {
    // Chiamata API update utente
    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        let body: any = {
          username: this.user.username,
          attributes: [
            // {
            //   Name: 'name',
            //   Value: this.user.name
            // },
            // {
            //   Name: 'family_name',
            //   Value: this.user.surname
            // }
          ],
          role: null
        };
        
        if (user.getUsername() !== this.user.username) {
          if (this.user.isAdmin) {
            body.role = 'admin';
          } else {
            body.role = 'user';
          }
        }
        this.userService.updateUserDetails(body).subscribe((res: Promise<any>) => {
          res
            .then(() => { this.modal.close(); })
            .catch((error) => { console.log(error); })
        })
      });
  }

}
