import { Component, OnInit, Input } from '@angular/core';
import { FurnitureService } from '../furniture.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Furniture } from '../furniture';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-furniture-delete',
  templateUrl: './furniture-delete.component.html',
  styleUrls: ['./furniture-delete.component.css']
})
export class FurnitureDeleteComponent implements OnInit {

  constructor(
    private furnitureService: FurnitureService,
    private amplifyService: AmplifyService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private messageService: MessageService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  @Input() furniture = new Furniture('', '', '', '', '', '', {}, []);

  deleteForniture() {
    this.spinner.show();

    this.amplifyService.auth().currentAuthenticatedUser() //TODO centralizzare
      .then(res => {
        let user;
        if (res.username) {
          user = res.username;
        } else {
          user = res.name;
        }
        this.modal.close();

        this.furnitureService.deleteFurniture(this.furniture.unique_id, user).subscribe((res: Promise<any>) => {
          res
            .then(() => {
              this.messageService.sendFurnitureMessage(this.furniture);
              this.spinner.hide();
            })
            .catch(() => {
              this.toastr.error(this.translateService.instant("furnitures.delete.messages.error.body"), this.translateService.instant("furnitures.delete.messages.error.title"), {
                positionClass: "toast-top-center",
                timeOut: 2500
              });
            })
        });
      })
      .catch(err => {
        console.log(err);
      });    
  }

  dismiss() {
    this.modal.dismiss();
  }
}
