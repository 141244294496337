import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private amplifyService: AmplifyService) { }

  canActivate() {
    return this.amplifyService.auth().currentAuthenticatedUser()
    .then((user: CognitoUser) => {
      if (user instanceof CognitoUser) {
        return this.amplifyService.auth().userSession(user)
          .then((session: CognitoUserSession) => {
            let token = session.getIdToken().decodePayload();
            if (token['cognito:groups'] && token['cognito:groups'].indexOf('admin') !== -1) {
              this.router.navigate(['/admin/furnitures']); 
              return false;    
            } else {
              return true;
            } 
          });
      } else {
        return true;
      }       
    })
    .catch((err) => {
      this.router.navigate(['/auth/login']);

      return false;
    })
  }
}