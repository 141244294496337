import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './/auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpConfirmComponent } from './sign-up/sign-up-confirm/sign-up-confirm.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    TranslateModule,
    FormsModule,
    NgxSpinnerModule
  ],
  declarations: [LoginComponent, SafeHtmlPipe, SignUpComponent, SignUpConfirmComponent, ForgotPasswordComponent, ResetPasswordComponent],
  entryComponents: [SignUpConfirmComponent, ResetPasswordComponent]
})
export class AuthModule { }
