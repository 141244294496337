import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signedIn: boolean;
  user: any;
  greeting: string;
  username: string;
  password: string;

  private myClientId: string = environment.clientId;

  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) {
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.signedIn = authState.state === 'signedIn';
        if (!authState.user) {
          this.user = null;
        } else {
          this.user = authState.user;
          this.greeting = "Hello " + this.user.username;
        }
      });

  }

  ngOnInit() {
    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        if (user instanceof CognitoUser) {
          return this.amplifyService.auth().userSession(user)
            .then((session: CognitoUserSession) => {
              let token = session.getIdToken().decodePayload();
              if (token['cognito:groups'] && token['cognito:groups'].indexOf('admin') !== -1) {
                this.router.navigate(['/admin/furnitures']);
              } else {
                this.router.navigate(['/']);
              }
            });
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch((err) => {
        this.spinner.show();
        setTimeout(() => {
          this.router.navigate(['/furnitures/dashboard']).then(() => {
            this.spinner.hide();
          }).catch(() => {
            this.spinner.hide();
          });          
        }, 2500);
      });
  }

  onSignIn() {
    this.amplifyService.auth().signIn(this.username, this.password)
      .then((user: CognitoUser) => {
        this.amplifyService.auth().userSession(user)
          .then((session: CognitoUserSession) => {
            let token = session.getIdToken().decodePayload();
            if (token['cognito:groups'] && token['cognito:groups'].indexOf('admin') !== -1) {
              this.router.navigate(['/admin/furnitures']);
            } else {
              this.router.navigate(['/furnitures/dashboard']);
            }
          });
      })
      .catch(err => {
        console.log(err);
        this.toastr.error(this.translateService.instant("auth.login.messages.error.wrong-credentials"), this.translateService.instant("auth.login.messages.error.title"));
      });
  }

  onAmazonSignIn() {
    this.amplifyService.auth().federatedSignIn({ provider: "LoginWithAmazon" });
  }

}
