import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Furniture } from "./furniture";

@Injectable()
export class MessageService {
  private furnitureSubject = new Subject<Furniture>();
  private headerSubject = new Subject<any>();

  sendFurnitureMessage(furniture: Furniture) {
    this.furnitureSubject.next(furniture);
  }

  getFurnitureMessage() {
    return this.furnitureSubject.asObservable();
  }

  sendHeaderMessage(profileInfo: any) {
    this.headerSubject.next(profileInfo);
  }

  getHeaderMessage() {
    return this.headerSubject.asObservable();
  }
}