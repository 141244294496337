import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpConfirmComponent } from './sign-up-confirm/sign-up-confirm.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
  }

  private myClientId: string = environment.clientId;

  private username: string;
  
  private name: string; 
  private surname: string;
  private email: string;

  private password: string;
  private confirmPassword: string;

  private errors = {
    username: true,
    email: true,
    password: true,
    confirmPassword: true
  };

  onSignUp() {
    if (!this.errors.username && !this.errors.password && !this.errors.confirmPassword && !this.errors.email) {
      this.spinner.show();
      this.amplifyService.auth()
        .signUp(this.username, this.password, this.email)
          .then((response: ISignUpResult) => {
            this.spinner.hide();
            const ref = this.modalService.open(SignUpConfirmComponent, { centered: true, size: "sm", backdrop: "static", keyboard: false });
            ref.componentInstance.user = response.user;
            
            ref.componentInstance.name = this.name;
            ref.componentInstance.surname = this.surname;
            ref.componentInstance.password = this.password;
          })
          .catch((err) => {
            this.spinner.hide();
            this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.username-exists"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
              timeOut: 2500
            });
          })
    }
  }

  validateField(field: string, value: string) {
    if (field === 'username') {      
      if (value.trim() !== '') {
        this.setElementBackground(field);
      } else {
        document.getElementById(field).style.background = "";
        this.unsetElementBackground(field);
      }
    } else if (field === 'password') {
      // Check confirm password if present
      if (this.confirmPassword) {
        if (this.confirmPassword === this.password) {
          this.setElementBackground('confirmPassword');
        } else {
          this.unsetElementBackground('confirmPassword');
        }
      }

      if (this.checkPasswordPolicy(value)) {
        this.setElementBackground(field)
      } else {
        this.unsetElementBackground(field);
        this.unsetElementBackground('confirmPassword');
      }
    } else if (field === 'confirmPassword') {
      if (this.password && value === this.password && this.checkPasswordPolicy(value)) {
        this.setElementBackground(field);
      } else {
        this.unsetElementBackground(field);        
      }
    } else if (field === 'email') {
      if (this.validateEmail(value)) {
        this.setElementBackground(field);
      } else {
        this.unsetElementBackground(field);        
      }
    }
  }

  setElementBackground(element) {
    document.getElementById(element).style.background = "url('/assets/images/ic-checked-font-awesome.png') 95% 0% no-repeat";
    document.getElementById(element).style.backgroundSize = "20px";

    this.errors[element] = false;
  }

  unsetElementBackground(element) {
    document.getElementById(element).style.background = "";
    this.errors[element] = true;
  }

  checkPasswordPolicy(password: string) {
    if (password.length >= 8
      && password.match(/[A-Z]/)
      && password.match(/[a-z]/)
      && password.match(/[0-9]/)
      && password.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/)) {
        return true;
      }
      return false;
  }

  validateEmail(email: string) {
    let regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  }
}
