import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FurnitureDetailComponent } from '../furniture-detail/furniture-detail.component';
import { Furniture } from '../furniture';
import { FurnitureService } from '../furniture.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(
    private furnitureService: FurnitureService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  onContinue(code) {
    this.spinner.show();

    this.furnitureService.getFurnitureByCode(code.value).subscribe((res: Promise<any>) => {
      res
        .then((furnitures) => {
          if (furnitures.length > 0) {
            let f = furnitures[0];
            this.modal.close();
            this.spinner.hide();

            const ref = this.modalService.open(FurnitureDetailComponent, { windowClass: 'xlarge-modal', centered: true, backdrop: "static", keyboard: false });
            ref.componentInstance.furniture = new Furniture(f.TYPE, f.UNIQUE_ID, f.BRAND, f.MODEL, f.VERSION, '', f.ATTRIBUTES, []);
            ref.componentInstance.viewType = 'INSERT';
          } else {
            this.spinner.hide();
            this.toastr.error(this.translateService.instant("furnitures.register.messages.error.body"), this.translateService.instant("furnitures.register.messages.error.title"), {
              positionClass: "toast-top-center",
              timeOut: 2500
            });
            // this.modal.close();
          }
        }).catch((error) => {
          console.log(error);
        })
    });
  }
}
