// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  clientId: 'amzn1.application-oa2-client.6895eef1c2224dcbbf3a0abb61917dea',

  amplify: {
    config: {
      Auth: {
        oauth: {
          domain: 'qas-domoticsfurniture.auth.us-east-1.amazoncognito.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'http://localhost:4200/auth/login',
          redirectSignOut: 'http://localhost:4200/auth/login',
          responseType: 'code'
        },
        region: 'us-east-1',
        userPoolId: 'us-east-1_iaWGrsKF4',
        userPoolWebClientId: '6kpikdutgmls50f6igfqcnqmps', //mette in ENV todo
        identityPoolId: 'us-east-1:cc919ffa-b5b2-4a40-a526-28ba26fb1621',
        cookieStorage: {
          domain: 'qas.domoticsfurniture.com',
          path: '/',
          expires: 30,
          secure: false
        },
      },
      Storage: {
        bucket: 'df-portal-test'
      }
    },
    logLevel: 'ERROR'
  },

  endpoint: {
    rootApi: 'https://ejofbceffh.execute-api.us-east-1.amazonaws.com/Stage',
    furnitures: {
      list:"/furnitures",
      upsert:"/furnitures",
      register: "/furnitures/register/",
      detail: "/furnitures/",
      delete: "/furnitures/"
    },
    firmwares: {
      firmwares: '/firmwares',
      files: '/files',
      distributions: '/distributions',
      distributeKeys: '/distribute-keys',
      distribute: '/distribute'
    },
    users: {
      users: '/users'
    }
  },
  
  defaultFurniture: '../assets/images/img-poltrona_big.png'
 
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
