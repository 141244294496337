import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NavService } from '../../nav.service';
import { NgbModal, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../user.service';
import { UserDetailsModalComponent } from './user-details/user-details-modal.component';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {

  private name: string;
  private surname: string;
  private email: string;
  private isAdmin: boolean;

  private userList = []; 
  private userListKeys = [];

  // Filtering
  private filter: any;
  private currentFilter: string;
  private filterText: string = this.translateService.instant('admin.user.list.filter.text');
  private filters = [
    { id: 'name', value: this.translateService.instant('admin.user.list.filter.name') },
    { id: 'family_name', value: this.translateService.instant('admin.user.list.filter.surname') },
    { id: 'username', value: this.translateService.instant('admin.user.list.filter.username') },
    { id: 'email', value: this.translateService.instant('admin.user.list.filter.email') },
    { id: 'admin', value: this.translateService.instant('admin.user.list.filter.admin') },
    { id: 'all-users', value: this.translateService.instant('admin.user.list.filter.all-users') },
  ];
  private searchTextControl = new FormControl();

  // Pagination
  private paginationToken: string;
  private index: number = 0;
  private backUserListIconColor: string = 'darkgrey';
  private forwardUserListIconColor: string = 'darkgrey';

  constructor(
    private amplifyService: AmplifyService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private navService: NavService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUsers();
    this.searchTextControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text: string) => {
        if (text !== "" && this.currentFilter && this.currentFilter !== 'admin') {
          this.filter = {
            filter: this.currentFilter,
            value: text
          };          
        } else {
          this.filter = {};
        }

        if (this.currentFilter !== 'admin') {
          this.getUsers(); 
        }        
      });
  }

  toggleSidenav() {
    this.navService.toggle();
  }

  getUsers(paginationToken?: string) {
    this.spinner.show();
    this.userList.splice(0, this.userList.length);
    
    this.userService.getUsers(this.filter, paginationToken).subscribe((res: Promise<any>) => {
      res
        .then(async (response) => {
          // response = JSON.parse(response.body);
          if (response.Users) {
            let users = response.Users;
            for (let i = 0; i < users.length; i++) {
              let element = users[i];
              
              let user: any = {
                username: element.Username
              };

              // Check groups from current user
              if (element.roles.indexOf('admin') !== -1) {
                user.isAdmin = true;
              }                            

              if (element.Attributes) {
                let attributes = element.Attributes;
                attributes.forEach((attribute: any) => {
                  if (attribute.Name === 'name') {
                    user.name = attribute.Value;
                  } else if (attribute.Name === 'family_name') {
                    user.surname = attribute.Value;
                  } else if (attribute.Name === 'email') {
                    user.email = attribute.Value;
                  }
                });
    
                this.userList.push(user);
              } else {
                this.userList.push(user);
              }
            }
            this.userList = [...this.userList];
          }
          
          // Pagination logic
          if (response.PaginationToken) {
            this.paginationToken = response.PaginationToken;
            this.userListKeys.splice(this.index, 0, response.PaginationToken);
            if (this.index === 0) { // first page
              this.backUserListIconColor = 'darkgrey';
              this.forwardUserListIconColor = 'black';
            } else { // page >= 1
              this.backUserListIconColor = 'black';
            }
          } else {
            if (this.index > 0) { 
              this.forwardUserListIconColor = 'darkgrey';
              this.backUserListIconColor = 'black';
            }
          }
    
          this.spinner.hide();
        }).catch((error) => {
          console.log(error);
        })
    });
  }

  changeFilter(filter: any) {
    this.filterText = filter.value;
    this.currentFilter = filter.id;
    
    if (this.currentFilter === 'admin') {
      this.filter = {
        filter: this.currentFilter
      };

      this.unsetPaginationLogic();
      this.getUsers();
    } else if (this.currentFilter === 'all-users') {
      this.filter = {};

      this.unsetPaginationLogic();
      this.getUsers();
    }
  }

  unsetPaginationLogic() {
    this.index = 0;
    this.userListKeys
    this.paginationToken = null;
    this.backUserListIconColor = 'darkgrey';
    this.forwardUserListIconColor = 'darkgrey';
  }

  openUserDetailsModal(event: any) {
    if (event.type === 'click' && event.column.prop === 'details') {
      event.cellElement.blur();

      const ref = this.modalService.open(UserDetailsModalComponent, { size: 'lg', backdrop: 'static' });
      ref.componentInstance.user = JSON.parse(JSON.stringify(event.row));

      ref.result
        .then(() => {
          this.toastr.success(this.translateService.instant('admin.user.messages.success.body'), this.translateService.instant('admin.user.messages.success.title'));
          this.getUsers();
        })
        .catch(() => {})
    }
  }

  incrementUserListIndex() {
    if (this.forwardUserListIconColor === 'black') {
      this.index++;
      this.getUsers(this.paginationToken);
    } 
  }

  decrementUserListIndex() {
    if (this.backUserListIconColor === 'black') {
      this.index--;
      if (this.index === 0) { // first page
        this.getUsers();
      } else {
        this.getUsers(this.userListKeys[this.index - 1]);
      }
    }
  }  

}
