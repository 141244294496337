import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NavService } from '../../nav.service';
import { TranslateService } from '@ngx-translate/core';
import { FurnitureService } from 'src/app/furnitures/furniture.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AmplifyService } from 'aws-amplify-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from 'src/app/furnitures/register/register.component';
import { FurnitureDetailComponent } from 'src/app/furnitures/furniture-detail/furniture-detail.component';
import { Furniture } from 'src/app/furnitures/furniture';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/furnitures/message.service';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-admin-furniture',
  templateUrl: './admin-furniture.component.html',
  styleUrls: ['./admin-furniture.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminFurnitureComponent implements OnInit, OnDestroy {

  private furnitureTabs = [
    { title: this.translateService.instant("furnitures.dashboard.tabs.all"), type: "ALL" },
    { title: this.translateService.instant("furnitures.dashboard.tabs.armchairs"), type: "CHAIR" },
    { title: this.translateService.instant("furnitures.dashboard.tabs.sofas"), type: "SOFA" },
    // { title: this.translateService.instant("furnitures.dashboard.tabs.wardrobes"), type: "WARDROBE"} TODO da reinserire 
  ];

  private furnitures = [];

  private all = [];
  private armchairs = [];
  private sofas = [];
  private wardrobes = [];

  private notInsertedAll = [];
  private notInsertedArmchairs = [];
  private notInsertedSofas = [];
  private notInsertedWardrobes = [];

  private TAG_FLAG: number = 3;

  // View selector
  private gridViewIcon: string = "../../../../assets/images/ic-grid.png";
  private tableViewIcon: string = "../../../../assets/images/ic-table--selected.png";
  private tabViewSelected: string = "table";

  // Filters
  private brand: string;
  private model: string;
  private version: string;
  private owner: string;

  private brandTextControl = new FormControl();
  private modelTextControl = new FormControl();
  private versionTextControl = new FormControl();
  private ownerTextControl = new FormControl();

  // Paginator
  private activeTab: string = '';
  private activeArrayByTab: string = 'ALL';
  private pageLimit: number = 10;

  private backColor = "darkgrey";
  private forwardColor = "darkgrey";

  private index: number = 0;
  private furnitureKeys = [];
  private lastFurnitureKey: string;

  public furnitureSubscription: Subscription;

  constructor(
    private navService: NavService,
    private translateService: TranslateService,
    private furnitureService: FurnitureService,
    private spinner: NgxSpinnerService,
    private amplifyService: AmplifyService,
    private modalService: NgbModal,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getFurnitures();

    this.furnitureSubscription = this.messageService.getFurnitureMessage().subscribe(furniture => {
      this.clearArrays();
      this.getFurnitures();
    });

    // Filters debounce
    // Brand
    this.brandTextControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text: string) => {
        if (text !== "") {
          this.brand = text;
        } else {
          this.brand = null;
        }
        this.clearArrays();
        this.getFurnitures();
      });
    // Model
    this.modelTextControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text: string) => {
        if (text !== "") {
          this.model = text;
        } else {
          this.model = null;
        }
        this.clearArrays();
        this.getFurnitures();
      });
    // Version
    this.versionTextControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text: string) => {
        if (text !== "") {
          this.version = text;
        } else {
          this.version = null;
        }
        this.clearArrays();
        this.getFurnitures();
      });
    // Owner 
    this.ownerTextControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((text: string) => {
        if (text !== "") {
          this.owner = text;
        } else {
          this.owner = null;
        }
        this.clearArrays();
        this.getFurnitures();
      });
  }

  toggleSidenav() {
    this.navService.toggle();
  }

  getFurnitures(lastKey?: string): void {
    this.spinner.show();
    this.amplifyService.auth().currentAuthenticatedUser()
      .then(res => {
        let filters = {
          brand: this.brand,
          model: this.model,
          version: this.version,
          owner: this.owner
        };

        this.furnitureService.getFurnitures(res.username, lastKey, filters).subscribe((res: Promise<any>) => {
          res
            .then((furnitures => {
              // Evaluate elements by type
              let armchairs = this.getElementsByType(furnitures.elements, 'CHAIR');
              let sofas = this.getElementsByType(furnitures.elements, 'SOFA');
              let wardrobes = this.getElementsByType(furnitures.elements, 'WARDROBE');

              // Recursion base step
              if (this.activeArrayByTab === 'ALL') {
                if (this.all.length === this.pageLimit || (furnitures.elements.length === 0 && !furnitures.lastKey)) {
                  if (this.all.length === this.pageLimit) {
                    this.forwardColor = 'black';
                    if (this.index > 0) {
                      this.backColor = 'black';
                    }
                  } else {
                    if (this.index > 0) {
                      this.backColor = 'black';
                      this.forwardColor = 'darkgrey';
                    } else { // single page result
                      this.backColor = 'darkgrey';
                      this.forwardColor = 'black';
                    }
                  }
                  this.spinner.hide();
                  return;
                }
              } else if (this.activeArrayByTab === 'ARMCHAIRS') {
                if (this.armchairs.length === this.pageLimit || (armchairs.length === 0 && !furnitures.lastKey)) {
                  if (this.armchairs.length === this.pageLimit) {
                    this.forwardColor = 'black';
                    if (this.index > 0) {
                      this.backColor = 'black';
                    }
                  } else {
                    if (this.index > 0) {
                      this.backColor = 'black';
                      this.forwardColor = 'darkgrey';
                    } else { // single page result
                      this.backColor = 'darkgrey';
                      this.forwardColor = 'black';
                    }
                  }
                  this.spinner.hide();
                  return;
                }
              } else if (this.activeArrayByTab === 'SOFAS') {
                if (this.sofas.length === this.pageLimit || (sofas.length === 0 && !furnitures.lastKey)) {
                  if (this.sofas.length === this.pageLimit) {
                    this.forwardColor = 'black';
                    if (this.index > 0) {
                      this.backColor = 'black';
                    }
                  } else {
                    if (this.index > 0) {
                      this.backColor = 'black';
                      this.forwardColor = 'darkgrey';
                    } else { // single page result
                      this.backColor = 'darkgrey';
                      this.forwardColor = 'black';
                    }
                  }
                  this.spinner.hide();
                  return;
                }
              } else if (this.activeArrayByTab === 'WARDROBES') {
                if (this.wardrobes.length === this.pageLimit || (wardrobes.length === 0 && !furnitures.lastKey)) { // TODO vedi sopra + correggere e contare tipo furniture 
                  if (this.wardrobes.length === this.pageLimit) {
                    this.forwardColor = 'black';
                    if (this.index > 0) {
                      this.backColor = 'black';
                    }
                  } else {
                    if (this.index > 0) {
                      this.backColor = 'black';
                      this.forwardColor = 'darkgrey';
                    } else { // single page result
                      this.backColor = 'darkgrey';
                      this.forwardColor = 'black';
                    }
                  }
                  this.spinner.hide();
                  return;
                }
              }

              // previous furnitures to insert in this page (by tab type)
              if (this.activeArrayByTab === 'ALL') {
                if (this.notInsertedAll.length > 0 && this.notInsertedAll.length < furnitures.elements.length) {
                  furnitures.elements = [...this.notInsertedAll];
                  this.notInsertedAll.splice(0, this.notInsertedAll.length);
                } else {
                  furnitures.elements = furnitures.elements.concat(this.notInsertedAll);
                  this.notInsertedAll.splice(0, this.notInsertedAll.length);
                }
              } else if (this.activeArrayByTab === 'ARMCHAIRS') {
                if (this.notInsertedArmchairs.length > 0 && this.notInsertedArmchairs.length < armchairs.length) {
                  armchairs = [...this.notInsertedArmchairs];
                  this.notInsertedArmchairs.splice(0, this.notInsertedArmchairs.length);
                } else {
                  armchairs = armchairs.concat(this.notInsertedArmchairs);
                  this.notInsertedArmchairs.splice(0, this.notInsertedArmchairs.length);
                }
              } else if (this.activeArrayByTab === 'SOFAS') {
                if (this.notInsertedSofas.length > 0 && this.notInsertedSofas.length < sofas.length) {
                  sofas = [...this.notInsertedSofas];
                  this.notInsertedSofas.splice(0, this.notInsertedSofas.length);
                } else {
                  sofas = sofas.concat(this.notInsertedSofas);
                  this.notInsertedSofas.splice(0, this.notInsertedSofas.length);
                }
              } else if (this.activeArrayByTab === 'WARDROBES') {
                if (this.notInsertedWardrobes.length > 0 && this.notInsertedWardrobes.length < wardrobes.length) {
                  wardrobes = [...this.notInsertedWardrobes];
                  this.notInsertedWardrobes.splice(0, this.notInsertedWardrobes.length);
                } else {
                  wardrobes = wardrobes.concat(this.notInsertedWardrobes);
                  this.notInsertedWardrobes.splice(0, this.notInsertedWardrobes.length);
                }
              }

              if (this.activeArrayByTab === 'ALL') {
                for (let i = 0; i < furnitures.elements.length; i++) {
                  let furniture = furnitures.elements[i];
                  if (this.all.length < this.pageLimit) {
                    let asset = "../../../assets/images/furnitures/" + furniture.TYPE + "-" + furniture.BRAND + "-" + furniture.MODEL + "-" + furniture.VERSION + ".jpg";
                    if (!furniture.TYPE || furniture.TYPE === 'undefined'
                      || !furniture.BRAND || furniture.BRAND === 'undefined'
                      || !furniture.MODEL || furniture.MODEL === 'undefined'
                      || !furniture.VERSION || furniture.VERSION === 'undefined') {
                      asset = environment.defaultFurniture;
                    }

                    this.all.push({
                      name: {
                        icon: asset,
                        text: furniture.NAME
                      },
                      typology: furniture.TYPE,
                      brandModelVersion: furniture.BRAND + " | " + furniture.MODEL + " | " + furniture.VERSION,
                      tags: furniture.TAGS,
                      owner: furniture.USER_ID,
                      asset: asset,
                      uniqueId: furniture.UNIQUE_ID,
                      attributes: furniture.ATTRIBUTES
                    });
                  } else {
                    this.notInsertedAll.push(furniture);
                  }
                }
              } else if (this.activeArrayByTab === 'ARMCHAIRS') {
                for (let i = 0; i < armchairs.length; i++) {
                  let furniture = armchairs[i];
                  if (this.armchairs.length < this.pageLimit) {
                    let asset = "../../../assets/images/furnitures/" + furniture.TYPE + "-" + furniture.BRAND + "-" + furniture.MODEL + "-" + furniture.VERSION + ".jpg";
                    if (!furniture.TYPE || furniture.TYPE === 'undefined'
                      || !furniture.BRAND || furniture.BRAND === 'undefined'
                      || !furniture.MODEL || furniture.MODEL === 'undefined'
                      || !furniture.VERSION || furniture.VERSION === 'undefined') {
                      asset = environment.defaultFurniture;
                    }

                    this.armchairs.push({
                      name: {
                        icon: asset,
                        text: furniture.NAME
                      },
                      typology: furniture.TYPE,
                      brandModelVersion: furniture.BRAND + " | " + furniture.MODEL + " | " + furniture.VERSION,
                      tags: furniture.TAGS,
                      owner: furniture.USER_ID,
                      asset: asset,
                      uniqueId: furniture.UNIQUE_ID,
                      attributes: furniture.ATTRIBUTES
                    });
                  } else {
                    this.notInsertedArmchairs.push(furniture);
                  }
                }
              } else if (this.activeArrayByTab === 'SOFAS') {
                for (let i = 0; i < sofas.length; i++) {
                  let furniture = sofas[i];
                  if (this.sofas.length < this.pageLimit) {
                    let asset = "../../../assets/images/furnitures/" + furniture.TYPE + "-" + furniture.BRAND + "-" + furniture.MODEL + "-" + furniture.VERSION + ".jpg";
                    if (!furniture.TYPE || furniture.TYPE === 'undefined'
                      || !furniture.BRAND || furniture.BRAND === 'undefined'
                      || !furniture.MODEL || furniture.MODEL === 'undefined'
                      || !furniture.VERSION || furniture.VERSION === 'undefined') {
                      asset = environment.defaultFurniture;
                    }

                    this.sofas.push({
                      name: {
                        icon: asset,
                        text: furniture.NAME
                      },
                      typology: furniture.TYPE,
                      brandModelVersion: furniture.BRAND + " | " + furniture.MODEL + " | " + furniture.VERSION,
                      tags: furniture.TAGS,
                      owner: furniture.USER_ID,
                      asset: asset,
                      uniqueId: furniture.UNIQUE_ID,
                      attributes: furniture.ATTRIBUTES
                    });
                  } else {
                    this.notInsertedSofas.push(furniture);
                  }
                }
              } else if (this.activeArrayByTab === 'WARDROBES') {
                for (let i = 0; i < wardrobes.length; i++) {
                  let furniture = wardrobes[i];
                  if (this.wardrobes.length < this.pageLimit) {
                    let asset = "../../../assets/images/furnitures/" + furniture.TYPE + "-" + furniture.BRAND + "-" + furniture.MODEL + "-" + furniture.VERSION + ".jpg";
                    if (!furniture.TYPE || furniture.TYPE === 'undefined'
                      || !furniture.BRAND || furniture.BRAND === 'undefined'
                      || !furniture.MODEL || furniture.MODEL === 'undefined'
                      || !furniture.VERSION || furniture.VERSION === 'undefined') {
                      asset = environment.defaultFurniture;
                    }

                    this.wardrobes.push({
                      name: {
                        icon: asset,
                        text: furniture.NAME
                      },
                      typology: furniture.TYPE,
                      brandModelVersion: furniture.BRAND + " | " + furniture.MODEL + " | " + furniture.VERSION,
                      tags: furniture.TAGS,
                      owner: furniture.USER_ID,
                      asset: asset,
                      uniqueId: furniture.UNIQUE_ID,
                      attributes: furniture.ATTRIBUTES
                    });
                  } else {
                    this.notInsertedWardrobes.push(furniture);
                  }
                }
              }

              this.all = [...this.all];
              this.armchairs = [...this.armchairs];
              this.sofas = [...this.sofas];
              this.wardrobes = [...this.wardrobes];

              // Pagination logic
              if (furnitures.lastKey) {
                this.lastFurnitureKey = furnitures.lastKey;
                this.furnitureKeys.splice(this.index, 0, furnitures.lastKey);
                if (this.activeArrayByTab === 'ALL') {
                  if (furnitures.elements.length === this.pageLimit) { // fixed page limit
                    this.forwardColor = "black";
                    if (this.index > 0) {
                      this.backColor = "black";
                    } else {
                      this.backColor = "darkgrey";
                    }
                  } else { // recursive step: i have minus than pageLimit rows in table => get next page
                    this.getFurnitures(furnitures.lastKey);
                  }
                } else if (this.activeArrayByTab === 'ARMCHAIRS') {
                  if (armchairs.length === this.pageLimit) { // fixed page limit
                    this.forwardColor = "black";
                    if (this.index > 0) {
                      this.backColor = "black";
                    } else {
                      this.backColor = "darkgrey";
                    }
                  } else { // recursive step: i have minus than pageLimit rows in table => get next page
                    this.getFurnitures(furnitures.lastKey);
                  }
                } else if (this.activeArrayByTab === 'SOFAS') {
                  if (sofas.length === this.pageLimit) { // fixed page limit
                    this.forwardColor = "black";
                    if (this.index > 0) {
                      this.backColor = "black";
                    } else {
                      this.backColor = "darkgrey";
                    }
                  } else { // recursive step: i have minus than pageLimit rows in table => get next page
                    this.getFurnitures(furnitures.lastKey);
                  }
                } else if (this.activeArrayByTab === 'WARDROBES') {
                  if (wardrobes.length === this.pageLimit) { // fixed page limit
                    this.forwardColor = "black";
                    if (this.index > 0) {
                      this.backColor = "black";
                    } else {
                      this.backColor = "darkgrey";
                    }
                  } else { // recursive step: i have minus than pageLimit rows in table => get next page
                    this.getFurnitures(furnitures.lastKey);
                  }
                }
              } else {
                if (this.activeArrayByTab === 'ALL') {
                  this.evaluatePaginationButtonsValue(furnitures.elements, this.notInsertedAll);
                } else if (this.activeArrayByTab === 'ARMCHAIRS') {
                  this.evaluatePaginationButtonsValue(armchairs, this.notInsertedArmchairs);
                } else if (this.activeArrayByTab === 'SOFAS') {
                  this.evaluatePaginationButtonsValue(sofas, this.notInsertedSofas);
                } else if (this.activeArrayByTab === 'WARDROBES') {
                  this.evaluatePaginationButtonsValue(wardrobes, this.notInsertedWardrobes);
                }
              }

              this.spinner.hide();

              this.all = [...this.all];
              this.armchairs = [...this.armchairs];
              this.sofas = [...this.sofas];
              this.wardrobes = [...this.wardrobes];
            })
          ).catch((error) => {
            console.log(error);
          })}
        )}
      )
  }

  getElementsByType(elements: any[], type: string): any[] {
    let typeElements = [];
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].TYPE === type) {
        typeElements.push(elements[i]);
      }
    }

    return typeElements;
  }

  evaluatePaginationButtonsValue(furnitures: any[], notInsertedRows: any[]) {
    if (this.index > 0 || furnitures.length === this.pageLimit) {
      if (notInsertedRows.length === 0) { // no element out of rows, last page
        if (this.index === 0) { // single page result
          this.backColor = 'darkgrey';
          this.forwardColor = 'darkgrey';
        } else {
          this.backColor = 'black';
          this.forwardColor = 'darkgrey';
        }
      } else {
        if (this.index > 0) {
          this.backColor = 'black'; // first page
        } else {
          this.backColor = 'darkgrey'; // not first page
        }
        this.forwardColor = 'black';
      }
    } else { // single page result
      this.backColor = 'darkgrey';
      this.forwardColor = 'darkgrey';
    }
  }

  changeTab(event) {
    this.activeTab = event.nextId;
    this.activeArrayByTab = event.nextId === 'ngb-tab-0' ? 'ALL'
      : event.nextId === 'ngb-tab-1' ? 'ARMCHAIRS'
        : event.nextId === 'ngb-tab-2' ? 'SOFAS'
          : 'WARDROBES';

    this.backColor = "darkgrey";
    this.forwardColor = "darkgrey";
    this.index = 0;

    this.clearArrays();
    this.cleanFilters();
    this.getFurnitures();
  }

  clearArrays() {
    this.all.splice(0, this.all.length);
    this.armchairs.splice(0, this.armchairs.length);
    this.sofas.splice(0, this.sofas.length);
    this.wardrobes.splice(0, this.wardrobes.length);
  }

  changeTabView(view: string) {
    this.cleanFilters();
    if (view === "grid") {
      this.gridViewIcon = "../../../../assets/images/ic-grid--selected.png"
      this.tableViewIcon = "../../../../assets/images/ic-table.png";

      this.tabViewSelected = "grid";

      this.backColor = "darkgrey";
      this.forwardColor = "darkgrey";
      this.index = 0;
      
      this.clearArrays();
      this.getFurnitures();
    } else {
      this.gridViewIcon = "../../../../assets/images/ic-grid.png"
      this.tableViewIcon = "../../../../assets/images/ic-table--selected.png";

      this.tabViewSelected = "table";
    }
  }

  cleanFilters() {
    this.brand = null;
    this.model = null;
    this.version = null;
    this.owner = null;
  }

  incrementIndex() {
    if (this.forwardColor === "black") {
      this.index++;
      this.clearArrays();
      this.getFurnitures(this.lastFurnitureKey)
    }
  }

  decrementIndex() {
    if (this.backColor === "black") {
      this.index--;
      this.clearArrays();
      if (this.index === 0) { // first page
        this.getFurnitures();
      } else {
        this.getFurnitures(this.furnitureKeys[this.index - 1]);
      }
    }
  }

  showAddNewFornitureModal() {
    this.modalService.open(RegisterComponent, { centered: true, size: "lg", backdrop: "static", keyboard: false });
  }

  viewFurnitureDetail(furniture) {
    if (this.tabViewSelected === 'grid') {
      let brandModelVersion = furniture.brandModelVersion.split(' | ');

      const ref = this.modalService.open(FurnitureDetailComponent, { windowClass: 'xlarge-modal', centered: true, backdrop: "static", keyboard: false });
      ref.componentInstance.viewType = 'VIEW';
      ref.componentInstance.furniture = new Furniture(furniture.typology, furniture.uniqueId, brandModelVersion[0], brandModelVersion[1], brandModelVersion[2], furniture.name.text, furniture.attributes, furniture.tags);
    } else {
      if (furniture.event.type === 'click') {
        furniture.cellElement.blur();

        let brandModelVersion = furniture.row.brandModelVersion.split(' | ');

        const ref = this.modalService.open(FurnitureDetailComponent, { windowClass: 'xlarge-modal', centered: true, backdrop: "static", keyboard: false });
        ref.componentInstance.viewType = 'VIEW';
        ref.componentInstance.furniture = new Furniture(furniture.row.typology, furniture.row.uniqueId, brandModelVersion[0], brandModelVersion[1], brandModelVersion[2], furniture.row.name.text, furniture.row.attributes, furniture.row.tags);
      }
    }
  }

  ngOnDestroy(): void {
    this.furnitureSubscription.unsubscribe();
  }

}
