import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { AppService } from "src/app/app.service";
import { CognitoUser } from "amazon-cognito-identity-js";

@Injectable()
export class UserService {
  private userListUrl = environment.endpoint.rootApi + environment.endpoint.users.users;
  
  constructor(
    private http: HttpClient,
    private appService: AppService
  ) {
    
  }

  getUsers(filter?: any, paginationToken?: string): Observable<any> { 
    let request = this.userListUrl;
    request = paginationToken ? request + "?paginationToken=" + encodeURI(paginationToken) : request;

    if (filter && filter.filter && filter.value) {
      request = request.indexOf("?") !== -1 
        ? request + "&filter=" + filter.filter + "&value=" + filter.value 
        : request + "?filter=" + filter.filter + "&value=" + filter.value ;
    }

    if (filter && filter.filter && filter.filter === 'admin') {
      request = request.indexOf("?") !== -1 
        ? request + "&filter=" + filter.filter 
        : request + "?filter=" + filter.filter;
    }

    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(request, { headers: headers }).toPromise();
        }
      ));
  }

  updateUserDetails(body: any): Observable<any> {
    let request = this.userListUrl;
    
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.put(request, body, { headers: headers }).toPromise();
        }
      ));
  }

}