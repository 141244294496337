import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CognitoUserAttribute, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/furnitures/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProfileConfirmComponent } from './edit-profile-confirm/edit-profile-confirm.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private translateService: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  private currentProfileImage: File;

  // Form field
  private name: string;
  private surname: string;
  
  private email: string;
  private oldEmail: string;

  private oldPassword: string;
  private password: string;
  private confirmPassword: string;

  private blockedField: boolean = false;

  private fieldContainerClassName: string = "info-profile-field-container-first";
  private fieldContainerClassSurname: string = "info-profile-field-container";
  private fieldContainerClassEmail: string = "info-profile-field-container";
  private fieldContainerClassOldPassword: string = "info-profile-field-container";
  private fieldContainerClassNewPassword: string = "info-profile-field-container";
  private fieldContainerClassConfirmPassword: string = "info-profile-field-container";

  private profileImageSrc: any = "../../../assets/images/ic-profile_placeholder@2x.png";

  ngOnInit() {
    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user) => {
        this.amplifyService.auth().userAttributes(user)
          .then((attributes: CognitoUserAttribute[]) => {
            attributes.forEach((attribute: CognitoUserAttribute) => {
              if (attribute.getName() === "name") {
                this.name = attribute.getValue();
              } else if (attribute.getName() === "family_name") {
                this.surname = attribute.getValue();
              } else if (attribute.getName() === "email") {
                this.email = attribute.getValue();
                this.oldEmail = JSON.parse(JSON.stringify(this.email));
              } else if (attribute.getName() === "picture") {
                this.amplifyService.storage().get(attribute.getValue())
                  .then((res) => {
                    this.profileImageSrc = res;
                  })
                  .catch(err => console.log(err))                
              } else if (attribute.getName() === 'identities') {
                this.blockedField = true;
              } 
            });
          })
      })
  }

  settingUpProfileImage(event) {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.profileImageSrc = fileReader.result;
      this.currentProfileImage = event.target.files[0];
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  goBackHome() {
    this.router.navigate(["/furnitures/dashboard"]);
  }

  validateForm(): any {
    if (this.name) {
      this.name.trim();
      if (this.name === "") {
        return { isValid: false, message: "users.edit-profile.messages.error.name" }
      }
    } 
    if (this.surname) {
      this.surname.trim();
      if (this.surname === "") {
        return { isValid: false, message: "users.edit-profile.messages.error.surname" }
      }
    }
    if (this.email) {
      this.email.trim();
      if (this.email === "") {
        return { isValid: false, message: "users.edit-profile.messages.error.email" }
      }
    }
    if (this.password) {
      this.password.trim();
      if (this.password === "") {
        return { isValid: false, message: "users.edit-profile.messages.error.password" }
      } else {
        if (this.password !== this.confirmPassword) {
          return { isValid: false, message: "users.edit-profile.messages.error.confirm-password" }
        } else {
          if (!this.oldPassword || this.oldPassword.trim() === "") {
            return { isValid: false, message: "users.edit-profile.messages.error.old-password" }
          } else {
            // Password policy validation
            if (!this.checkPasswordPolicy(this.password)) {
              return { isValid: false, message: "users.edit-profile.messages.error.password-policy" }
            }
          }          
        }
      }
    }

    return { isValid: true };
  }

  showValidCheckPasswordPolicy() {
    if (this.password) {
      return !this.checkPasswordPolicy(this.password);
    } 
    return true;    
  }

  showValidCheckConfirmPasswordPolicy() {
    if (this.password && this.confirmPassword) {
      return !(this.password === this.confirmPassword);
    }
    return true;
  }

  checkPasswordPolicy(password: string) {
    if (password.length >= 8
      && password.match(/[A-Z]/)
      && password.match(/[a-z]/)
      && password.match(/[0-9]/)
      && password.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/)) {
        return true;
      }
      return false;
  }

  updateProfileInfo() {
    let validation = this.validateForm();
    let toastOptions = {
      positionClass: "toast-top-right",
      timeOut: 2500
    }

    if (!validation.isValid) {
      this.toastr.error(this.translateService.instant(validation.message), this.translateService.instant("users.edit-profile.messages.error.title"), toastOptions);
    } else {
      this.spinner.show();
      this.amplifyService.auth().currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          // Profile image
          if (this.currentProfileImage) {
            this.amplifyService.storage()
              .put("profile-images" + "/" + user.getUsername() + "/" + this.currentProfileImage.name, this.currentProfileImage, { contentType: this.currentProfileImage.type })
                .then((image: any) => { 
                  // Send message for header update
                  this.messageService.sendHeaderMessage(
                    { 
                      name: this.name,
                      family_name: this.surname,
                      picture: image.key
                    }
                  );

                  this.amplifyService.auth().updateUserAttributes(user, 
                    { 
                      name: this.name,
                      family_name: this.surname,
                      email: this.email,
                      picture: image.key 
                    })
                      .then(() => {
                        // Password  
                        if (this.password) {
                          this.amplifyService.auth().changePassword(user, this.oldPassword, this.password)
                            .then(() => {
                              this.password = null;
                              this.oldPassword = null;
                              this.confirmPassword = null;

                              this.spinner.hide();

                              if (this.oldEmail !== this.email) {
                                this.oldEmail = JSON.parse(JSON.stringify(this.email));
                                this.modalService.open(EditProfileConfirmComponent, { centered: true, size: "sm", backdrop: "static", keyboard: false });
                              }

                              this.toastr.success(this.translateService.instant("users.edit-profile.messages.success.message"), this.translateService.instant("users.edit-profile.messages.success.title"), toastOptions);
                            })
                            .catch((error) => { 
                              this.password = null;
                              this.oldPassword = null;
                              this.confirmPassword = null;

                              this.spinner.hide();
                              this.toastr.error(this.translateService.instant("users.edit-profile.messages.error.something-happened"), this.translateService.instant("users.edit-profile.messages.error.title"), toastOptions);
                            })
                        } else {
                          this.spinner.hide();

                          if (this.oldEmail !== this.email) {
                            this.oldEmail = JSON.parse(JSON.stringify(this.email));
                            this.modalService.open(EditProfileConfirmComponent, { centered: true, size: "sm", backdrop: "static", keyboard: false });
                          }
                          this.toastr.success(this.translateService.instant("users.edit-profile.messages.success.message"), this.translateService.instant("users.edit-profile.messages.success.title"), toastOptions);
                        }
                      })
                      .catch((err) => {
                        this.spinner.hide();
                        this.toastr.error(this.translateService.instant("users.edit-profile.messages.error.something-happened"), this.translateService.instant("users.edit-profile.messages.error.title"), toastOptions);
                      });
                })
                .catch((err) => { 
                  this.spinner.hide();
                  console.log(err); 
                });
          } else {
            // Send message for header update
            this.messageService.sendHeaderMessage(
              { 
                name: this.name,
                family_name: this.surname
              }
            );

            // Common attributes
            this.amplifyService.auth().updateUserAttributes(
              user, 
              {
                name: this.name,
                family_name: this.surname,
                email: this.email
              })
              .then(() => {
                // Password  
                if (this.password) {
                  this.amplifyService.auth().changePassword(user, this.oldPassword, this.password)
                    .then(() => {
                      this.password = null;
                      this.oldPassword = null;
                      this.confirmPassword = null;

                      this.spinner.hide();

                      if (this.oldEmail !== this.email) {
                        this.oldEmail = JSON.parse(JSON.stringify(this.email));
                        this.modalService.open(EditProfileConfirmComponent, { centered: true, size: "sm", backdrop: "static", keyboard: false });
                      }
                      this.toastr.success(this.translateService.instant("users.edit-profile.messages.success.message"), this.translateService.instant("users.edit-profile.messages.success.title"), toastOptions);
                    })
                    .catch((error) => { 
                      this.password = null;
                      this.oldPassword = null;
                      this.confirmPassword = null;

                      this.spinner.hide();
                      this.toastr.error(this.translateService.instant("users.edit-profile.messages.error.something-happened"), this.translateService.instant("users.edit-profile.messages.error.title"), toastOptions);
                    })
                } else {
                  this.spinner.hide();

                  if (this.oldEmail !== this.email) {
                    this.oldEmail = JSON.parse(JSON.stringify(this.email));
                    this.modalService.open(EditProfileConfirmComponent, { centered: true, size: "sm", backdrop: "static", keyboard: false });
                  }
                  this.toastr.success(this.translateService.instant("users.edit-profile.messages.success.message"), this.translateService.instant("users.edit-profile.messages.success.title"), toastOptions);
                }                
              })
              .catch((err) => {
                this.spinner.hide();
                this.toastr.error(this.translateService.instant("users.edit-profile.messages.error.something-happened"), this.translateService.instant("users.edit-profile.messages.error.title"), toastOptions);
              });
          } 
        })
    }
  }
}
