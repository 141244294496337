import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { AmplifyService } from "aws-amplify-angular";
import { CognitoUser } from "@aws-amplify/auth";
import { from } from 'rxjs';
import { AppService } from "src/app/app.service";

@Injectable()
export class FirmwareService {
  private firmwaresFilesListUrl = environment.endpoint.rootApi + environment.endpoint.firmwares.firmwares + environment.endpoint.firmwares.files;
  private firmwaresDistributionsListUrl = environment.endpoint.rootApi + environment.endpoint.firmwares.firmwares + environment.endpoint.firmwares.distributions;
  private firmwareDistributeKeysLookupUrl = environment.endpoint.rootApi + environment.endpoint.firmwares.firmwares + environment.endpoint.firmwares.distributeKeys;
  private firmwareDistributionUrl = environment.endpoint.rootApi + environment.endpoint.firmwares.firmwares + environment.endpoint.firmwares.distribute;

  constructor(
    private http: HttpClient,
    private amplifyService: AmplifyService,
    private appService: AppService
  ) { }

  getFirmwaresFilesList(lastKey?: any): Observable<any> {
    let request = this.firmwaresFilesListUrl;
    request = lastKey ? request + "?lastKey=" + encodeURI(JSON.stringify(lastKey)) : request;

    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(request, { headers: headers }).toPromise();
        }
      ));
  }

  getFirmwaresUpdateList(lastKey?: string): Observable<any> {
    let request = this.firmwaresDistributionsListUrl;
    request = lastKey ? request + "?lastKey=" + encodeURI(JSON.stringify(lastKey)) : request;

    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(request, { headers: headers }).toPromise();
        }
      ));
  }

  lookupDistributeKeys(type: string, brand?: string, model?: string): Observable<any> {
    let request = this.firmwareDistributeKeysLookupUrl + "?type=" + type;
    request = brand ? request + "&brand=" + brand : request;
    request = model ? request + "&model=" + model : request;
    
    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.get(request, { headers: headers }).toPromise();
        }
      ));
  }

  distributeFirmware(filename: string, type: string, id: string, brand?: string, model?: string, version?: string): Observable<any> {
    let body = {};
    if (type) {
      body = {
        filename: filename,
        type: type,
        brand: brand,
        model: model,
        version: version
      };
    } else {
      body = {
        filename: filename,
        id: id
      };
    }

    return this.appService.getCurrentUser()
      .pipe(map(
        (user: CognitoUser) => {
          let headers = new HttpHeaders();
          headers = headers.append('Authorization', user.getSignInUserSession().getIdToken().getJwtToken());
          
          return this.http.post(this.firmwareDistributionUrl, body, { headers: headers }).toPromise();
        }
      ));
  }
}