import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private amplifyService: AmplifyService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  private errors = {
    username: true
  };

  private username: string;

  validateUsername() {
    if (this.username && this.username.trim() !== "") {
      this.errors.username = false;
    } else {
      this.errors.username = true;
    }
  }

  onForgotPassword() {
    if (!this.errors.username) {
      this.amplifyService.auth().forgotPassword(this.username)
        .then((data) => {
          this.toastr.success(this.translateService.instant("auth.forgot-password.messages.success.email-sent"), this.translateService.instant("auth.forgot-password.messages.success.title"), {
            timeOut: 7500
          });
          const ref = this.modalService.open(ResetPasswordComponent, { centered: true, size: "lg", backdrop: "static", keyboard: false });
          ref.componentInstance.username = this.username;
        })
        .catch(() => {
          this.toastr.error(this.translateService.instant("auth.forgot-password.messages.errors.username-not-exists"), this.translateService.instant("auth.forgot-password.messages.errors.title"));
        })
    }    
  }
}
