import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirmwareService } from '../../firmware.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload-firmware-modal',
  templateUrl: './upload-firmware-modal.component.html',
  styleUrls: ['./upload-firmware-modal.component.css']
})
export class UploadFirmwareModalComponent implements OnInit {

  constructor(
    private modal: NgbActiveModal,
    private firmwareService: FirmwareService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  @Input() private name: string;
  // private total: number = 0;
  
  private typologies = [ 
    this.translateService.instant("admin.firmware.modal.typologies.all"),
    this.translateService.instant("admin.firmware.modal.typologies.chair")
  ];
  private brands = [];
  private models = [];
  private versions = [];
  private keys = [];

  private selectedTypology: string;
  private selectedBrand: string;
  private selectedModel: string;
  private selectedVersion: string;
  private selectedKey: string;

  onChange(event: any, type: string) {
    switch (type) {
      case 'typology': {
        this.selectedTypology = event.target.value;
        this.lookupValue(this.selectedTypology);

        break;
      }
      case 'brand': {
        this.selectedBrand = event.target.value;
        this.lookupValue(this.selectedTypology, this.selectedBrand);

        break;
      }
      case 'model': {
        this.selectedModel = event.target.value;
        this.lookupValue(this.selectedTypology, this.selectedBrand, this.selectedModel);

        break;
      }
      case 'version': {
        this.selectedVersion = event.target.value;
        break;
      }
      case 'key': {
        this.selectedKey = event.target.value;
        break;
      }
    }
  }

  lookupValue(type: string, brand?: string, model?: string) {
    if (type && !brand && !model) {
      this.firmwareService.lookupDistributeKeys(type).subscribe((res: Promise<any>) => {
        res
          .then((result) => {
            if (this.selectedTypology !== this.translateService.instant("admin.firmware.modal.typologies.all")) {
              this.brands = result;
            }
          })
          .catch(() => {})                
      });
    } else if (type && brand && !model) {
      this.firmwareService.lookupDistributeKeys(type, brand).subscribe((res: Promise<any>) => {
        res
          .then((result) => {
            this.models = result;    
          })
          .catch(() => {})        
      });
    } else if (type && brand && model) {
      this.firmwareService.lookupDistributeKeys(type, brand, model).subscribe((res: Promise<any>) => {
        res
          .then((result) => {
            this.versions = result;    
          })
          .catch(() => {})
      });
    }
  }

  distributeFirmware() {
    if (this.selectedTypology) {
      this.firmwareService.distributeFirmware(this.name, this.selectedTypology, null, this.selectedBrand, this.selectedModel, this.selectedVersion).subscribe(
        (res: Promise<any>) => {
          res.then(() => {
            this.toastr.success(this.translateService.instant("admin.firmware.modal.messages.success.body"), this.translateService.instant("admin.firmware.modal.messages.success.title"));
            this.modal.close();
          }).catch(() => {
            this.toastr.error(this.translateService.instant("admin.firmware.modal.messages.error.body"), this.translateService.instant("admin.firmware.modal.messages.error.title"));
          })          
        }
      );
    } else {
      this.firmwareService.distributeFirmware(this.name, null, this.selectedKey).subscribe(
        (res: Promise<any>) => {
          res.then(() => {
            this.toastr.success(this.translateService.instant("admin.firmware.modal.messages.success.body"), this.translateService.instant("admin.firmware.modal.messages.success.title"));
            this.modal.close();
          }).catch(() => {
            this.toastr.error(this.translateService.instant("admin.firmware.modal.messages.error.body"), this.translateService.instant("admin.firmware.modal.messages.error.title"));
          })          
        }
      );
    }
  }

}
