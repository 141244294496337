import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-de',
  templateUrl: './privacy-de.component.html',
  styleUrls: ['../privacy.component.css']
})
export class PrivacyDeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
