import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const DATE_FORMAT_LOCALE = {
  it: "DD/MM/YYYY",
  en: "MM/DD/YYYY"
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'portal';
  localeDateFormat: string;

  constructor(private translateService: TranslateService) {
    translateService.addLangs(["en"]);
    translateService.setDefaultLang('en');

    let browserLang: string = translateService.getBrowserLang();
    translateService.use(browserLang.match(/en/) ? browserLang : translateService.getDefaultLang());
    
    // Localize date format
    this.localeDateFormat = DATE_FORMAT_LOCALE.en;
    // if (browserLang.match(/it/)) {
    //   this.localeDateFormat = DATE_FORMAT_LOCALE.it;
    // } else {
    //   this.localeDateFormat = DATE_FORMAT_LOCALE.en;
    // }
  }
}
