import { Component, OnInit, OnDestroy } from '@angular/core';
import { FurnitureService } from '../furniture.service';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from '../register/register.component';
import { Subscription } from 'rxjs';
import { MessageService } from '../message.service';
import { Furniture } from '../furniture';
import { FurnitureDetailComponent } from '../furniture-detail/furniture-detail.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  static _GRID_STEP = 4;
  furnitures = [];

  private all = [];
  private armchairs = [];
  private sofas = [];
  private wardrobes = [];


  // Tag flag
  private TAG_FLAG = 3;

  private currentUser: string = "";
  private furnitureTabs = [];

  public furnitureSubscription: Subscription;

  constructor(
    private furnitureService: FurnitureService,
    private amplifyService: AmplifyService,
    private translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {    
    this.getFurnitures();

    this.furnitureSubscription = this.messageService.getFurnitureMessage().subscribe(furniture => {
      this.getFurnitures();
    })
  }

  getFurnitures(): void {
    this.spinner.show();
    let fs = this.furnitureService;
    this.amplifyService.auth().currentAuthenticatedUser() //TODO centralizzare
      .then(res => {
        let user;
        if (res.username) {
          this.currentUser = res.username;
          user = res.username;
        }
        else
          user = res.name;
          fs.getFurnitures(user).subscribe((res: Promise<any>) => {
            res
              .then((furnitures) => {
                furnitures = JSON.parse(furnitures);

                this.all.splice(0, this.all.length);
                this.armchairs.splice(0, this.armchairs.length);
                this.sofas.splice(0, this.sofas.length);
                this.wardrobes.splice(0, this.wardrobes.length);
                
                // Fill furniture tabs array
                this.furnitureTabs = [
                  { title: this.translateService.instant("furnitures.dashboard.tabs.all"), type: "ALL" },
                  { title: this.translateService.instant("furnitures.dashboard.tabs.armchairs"), type: "CHAIR" },
                  { title: this.translateService.instant("furnitures.dashboard.tabs.sofas"), type: "SOFA" },
                  // { title: this.translateService.instant("furnitures.dashboard.tabs.wardrobes"), type: "WARDROBE"} TODO da reinserire 
                ]

                for (let i = 0; i < furnitures.length; i++) {
                  let furniture = furnitures[i];
                  
                  if (!furniture.TYPE || furniture.TYPE === 'undefined' 
                      || !furniture.BRAND || furniture.BRAND === 'undefined'
                      || !furniture.MODEL || furniture.MODEL === 'undefined'
                      || !furniture.VERSION || furniture.VERSION === 'undefined') {
                      furniture.asset = environment.defaultFurniture; 
                  } else {
                    furniture.asset = "../../../assets/images/furnitures/" + furniture.TYPE + "-" + furniture.BRAND + "-" + furniture.MODEL + "-" + furniture.VERSION + ".jpg";
                  }

                  if (furniture.TYPE === "CHAIR") {
                    this.armchairs.push(furniture);              
                  } else if (furniture.TYPE === "SOFA") {
                    this.sofas.push(furniture);
                  } else if (furniture.TYPE === "WARDROBE") {
                    this.wardrobes.push(furniture);
                  }
                  this.all.push(furniture);
                }
                
                this.spinner.hide();
              })
              .catch((error) => {
                console.log(error);
              })
          })
          
      })
      .catch(err => {
        console.log(err);
      });
  }

  showAddNewFornitureModal() {
    this.modalService.open(RegisterComponent, { centered: true, size: "lg", backdrop: "static", keyboard: false });
  }

  viewFurnitureDetail(furniture) {
    let undefinedDetail = this.translateService.instant('furnitures.detail.undefined-detail');

    const ref = this.modalService.open(FurnitureDetailComponent, { windowClass: 'xlarge-modal', centered: true, backdrop: "static", keyboard: false });
    ref.componentInstance.furniture = new Furniture(furniture.TYPE, furniture.UNIQUE_ID, !furniture.BRAND ? undefinedDetail : furniture.BRAND, !furniture.MODEL ? undefinedDetail : furniture.MODEL, !furniture.VERSION ? undefinedDetail : furniture.VERSION, furniture.NAME, furniture.ATTRIBUTES, furniture.TAGS);
    ref.componentInstance.viewType = 'VIEW';
  }

  editProfile() {
    this.router.navigate(['/users/edit-profile'])
  }

  logout() {
    this.amplifyService.auth().signOut()
      .then(() => {
        this.router.navigate(['/auth/login'])
      })
      .catch(err => console.log(err));
  }

  ngOnDestroy(): void {
    this.furnitureSubscription.unsubscribe();
  }
}
