import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { Observable, from } from "rxjs";
import { CognitoUser } from "@aws-amplify/auth";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private amplifyService: AmplifyService
  ) {}

  getCurrentUser(): Observable<{} | CognitoUser> {
    return from(this.amplifyService.auth().currentAuthenticatedUser())
      .pipe(
        map((res: CognitoUser) => {
          return res;
        }),
        catchError((errors) => {
          return errors;
        })
      );
  }
}
