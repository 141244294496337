import { Component, OnInit } from '@angular/core';
import { Furniture } from '../furniture';
import { FurnitureService } from '../furniture.service';

@Component({
  selector: 'app-furniture-list',
  templateUrl: './furniture-list.component.html',
  styleUrls: ['./furniture-list.component.css']
})
export class FurnitureListComponent implements OnInit {

  furnitures: Furniture[];

  constructor(private furnitureService: FurnitureService) { }

  ngOnInit() {
    this.getFurnitures();
  }


  getFurnitures(): void {
   // this.furnitureService.getFurnitures().subscribe(furniture => this.furnitures = furniture); //TODO commentato per incompatibilità con il nuovo service, da sistemare quanto verrà costruito l'elenco
  }


}
