import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AuthGuard } from '../auth/auth-guard';
import { UsersComponent } from './users.component';

const usersRoutes: Routes = [
  {
    path: "users", 
    component: UsersComponent, 
    children: [
      {
        path: "edit-profile", component: EditProfileComponent, canActivate: [AuthGuard],
      },
      {
        path: "", pathMatch: 'full', redirectTo: "edit-profile"
      }
    ]    
  }
];

@NgModule({
  imports: [RouterModule.forChild(usersRoutes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
