import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../nav.service';
import { FirmwareService } from '../firmware.service';
import { AppComponent } from 'src/app/app.component';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFirmwareModalComponent } from './upload-firmware-modal/upload-firmware-modal.component';
import { DistributionDetailsModalComponent } from './distribution-details-modal/distribution-details-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-upload-firmware',
  templateUrl: './upload-firmware.component.html',
  styleUrls: ['./upload-firmware.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UploadFirmwareComponent implements OnInit {
  
  private name: string;
  private uploadedDate: string;
  private size: string;

  private dateFormat: string;
  private fileSizeDataDimension: string = "MB";
  private fileListRows = [];
  private updateListRows = [];

  private activeTab: string = "0";

  // Pagination
  private backFileListIconColor: string = "darkgrey";
  private forwardFileListIconColor: string = "darkgrey";
  private backUpdateListIconColor: string = "darkgrey";
  private forwardUpdateListIconColor: string = "darkgrey";

  private indexFileList: number = 0;
  private lastFileListKey: string;
  private fileListKeys = [];

  private indexUpdateList: number = 0;
  private lastUpdateListKey: string;
  private updateListKeys = [];

  constructor(
    private amplifyService: AmplifyService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private navService: NavService,
    private firmwareService: FirmwareService,
    private appComponent: AppComponent,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.dateFormat = this.appComponent.localeDateFormat;

    this.getFirmwaresFilesList();
    this.getFirmwaresUpdateList();
  }

  toggleSidenav() {
    this.navService.toggle();
  }

  changeTab(event) {
    this.activeTab = event.nextId;
    if (this.activeTab === "0") {
      if (this.indexFileList === 0) { // first page
        this.getFirmwaresFilesList();
      } else {
        this.getFirmwaresFilesList(this.lastFileListKey);
      }
    } else if (this.activeTab === "1") {
      if (this.indexUpdateList === 0) { // first page
        this.getFirmwaresUpdateList();
      } else {
        this.getFirmwaresUpdateList(this.lastFileListKey);
      }
    }
  }

  getFirmwaresFilesList(lastKey?: string) {
    this.spinner.show();
    this.firmwareService.getFirmwaresFilesList(lastKey).subscribe((res: Promise<any>) => {
      res
        .then((files) => {
          let elements = files.elements;
          for (let i = 0; i < elements.length; i++) {
            let file = elements[i];
            file.date = moment(file.date).format(this.dateFormat);
            file.size = (Math.round((file.size / 1000000) * 100) / 100) + " " + this.fileSizeDataDimension; 
          }
          this.fileListRows = elements;

          // Pagination logic
          if (files.lastKey) {
            this.lastFileListKey = files.lastKey;
            this.fileListKeys.splice(this.indexFileList, 0, files.lastKey);
            if (this.indexFileList === 0) { // first page
              this.backFileListIconColor = "darkgrey";
              this.forwardFileListIconColor = "black";
            } else { // page >= 1
              this.backFileListIconColor = "black";
            }
          } else {
            if (this.indexFileList > 0) { 
              this.forwardFileListIconColor = "darkgrey";
              this.backFileListIconColor = "black";
            }
          }
          this.spinner.hide();
        })
        .catch((error) => {
          console.log(error);
        })
    });
  }

  getFirmwaresUpdateList(lastKey?: string) {
    this.spinner.show();
    this.firmwareService.getFirmwaresUpdateList(lastKey).subscribe((res: Promise<any>) => {
      res
        .then((files) => {
          let elements = files.elements;
          for (let i = 0; i < elements.length; i++) {
            let file = elements[i];
            file.date = moment(file.date).format(this.dateFormat);
          }
          this.updateListRows = elements;

          // Pagination logic
          if (files.lastKey) {
            this.lastUpdateListKey = files.lastKey;
            this.updateListKeys.splice(this.indexUpdateList, 0, files.lastkey);
            if (this.indexUpdateList === 0) { // first page
              this.backUpdateListIconColor = "darkgrey";
              this.forwardUpdateListIconColor = "black";
            } else { // page >= 1
              this.backUpdateListIconColor = "black";
            }
          } else {
            if (this.indexUpdateList > 0) { 
              this.forwardUpdateListIconColor = "darkgrey";
              this.backUpdateListIconColor = "black";
            }
          }
          this.spinner.hide();
        })
        .catch((error) => {
          console.log(error);
        })      
    });
  }

  uploadFirmware(event) { // TODO: modificare path/logica di caricamento con gestione permessi
    let fileReader = new FileReader();
    fileReader.onload = () => {
      this.amplifyService.storage().put("firmwares/" + event.target.files[0].name, event.target.files[0], { contentType: event.target.files[0].type })
        .then(() => {
          this.fileListRows.push({
            name: event.target.files[0].name,
            date: moment().format(this.dateFormat),
            size: (Math.round((event.target.files[0].size / 1000000) * 100) / 100) + " " + this.fileSizeDataDimension
          });
          this.fileListRows = [...this.fileListRows];
          this.toastr.success(this.translateService.instant("admin.firmware.messages.success.message"), this.translateService.instant("admin.firmware.messages.success.title"));
        })
        .catch((err) => {
          this.toastr.error(this.translateService.instant("admin.firmware.messages.error.message"), this.translateService.instant("admin.firmware.messages.error.title"));
        });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  prepareFirmwareForDistribution(event) {
    if (event.type === "click") { 
      event.cellElement.blur();

      this.name = event.row.name;
      this.uploadedDate = event.row.date;
      this.size = event.row.size;
    }
  }

  showUpdateFirmwareModal() {
    const ref = this.modalService.open(UploadFirmwareModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    ref.componentInstance.name = this.name;
    
    ref.result
      .then(() => {
        this.fileListRows.forEach((row) => {
          if (row.name === this.name) {
            row.state = this.translateService.instant("admin.firmware.table.fileList.distributed");
            this.fileListRows = [...this.fileListRows];
          }
        })
      })
      .catch(() => {});
  }

  showDistributionDetails(event) {
    if (event.type === "click" && event.column.prop === "details") {
      event.cellElement.blur();

      const ref = this.modalService.open(DistributionDetailsModalComponent, { size: 'lg', backdrop: 'static' });
      ref.componentInstance.distribution = event.row;
    }
  }

  incrementFileListIndex() {
    if (this.forwardFileListIconColor === "black") {
      this.indexFileList++;
      this.getFirmwaresFilesList(this.lastFileListKey);
    }    
  }

  decrementFileListIndex() {
    if (this.backFileListIconColor === "black") {
      this.indexFileList--;
      if (this.indexFileList === 0) { // first page
        this.getFirmwaresFilesList();
      } else {
        this.getFirmwaresFilesList(this.fileListKeys[this.indexFileList - 1]);
      }
    }    
  }

  incrementUpdateListIndex() {
    if (this.forwardUpdateListIconColor === "black") {
      this.indexUpdateList++;
      this.getFirmwaresUpdateList(this.lastUpdateListKey);
    }    
  }

  decrementUpdateListIndex() {
    if (this.backUpdateListIconColor === "black") {
      this.indexUpdateList--;
      if (this.indexUpdateList === 0) { // first page
        this.getFirmwaresUpdateList();
      } else {
        this.getFirmwaresUpdateList(this.updateListKeys[this.indexUpdateList - 1]);
      }
    }
  }
}
