import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UsersComponent } from './users.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { HeadersModule } from '../headers/headers.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EditProfileConfirmComponent } from './edit-profile/edit-profile-confirm/edit-profile-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    TranslateModule,
    FormsModule,
    HeadersModule,
    NgxSpinnerModule
  ],
  declarations: [
    UsersComponent,
    EditProfileComponent,
    EditProfileConfirmComponent
  ],
  entryComponents: [EditProfileConfirmComponent]
})
export class UsersModule { }
