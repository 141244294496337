import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/furnitures/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private amplifyService: AmplifyService
  ) { }

  private name: String;
  private surname: String;
  public profileImageSrc: any = "../../../assets/images/ic-profile_placeholder@2x.png";

  private amazonUserName: string = "Logged in with Amazon";
  
  private currentUser: String;

  public headerSubscription: Subscription;

  ngOnInit() {
    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user) => {
        if (user.username) {
          this.amplifyService.auth().userAttributes(user)
          .then((attributes: CognitoUserAttribute[]) => {
            attributes.forEach((attribute: CognitoUserAttribute) => {
              if (attribute.getName() === "name") {
                this.name = attribute.getValue();
              } else if (attribute.getName() === "family_name") {
                this.surname = attribute.getValue();
              } else if (attribute.getName() === "picture") {
                this.amplifyService.storage().get(attribute.getValue())
                  .then((res) => {
                    this.profileImageSrc = res;
                  })
                  .catch(err => console.log(err))                
              } 
            });
            if (this.name && this.surname) {
              this.currentUser = this.name + " " + this.surname;
            } else {
              this.currentUser = user.username;              
            }
          });
        } else {
          this.currentUser = this.amazonUserName;
        }        
      })
      .catch(() => {})
    
    this.headerSubscription = this.messageService.getHeaderMessage().subscribe((profileInfo) => {
      this.amplifyService.auth().currentAuthenticatedUser()
        .then((user) => {
          // Name + Surname
          if (!profileInfo.name || profileInfo.name === "" || !profileInfo.family_name || profileInfo.family_name === "") {
            this.currentUser = user.username;
          } else {
            this.currentUser = profileInfo.name + " " + profileInfo.family_name;
          }

          // Profile picture
          if (profileInfo.picture) {
            this.amplifyService.storage().get(profileInfo.picture)
              .then((res) => {
                this.profileImageSrc = res;
              })
              .catch(err => console.log(err))
          }          
        })
        .catch((err) => console.log(err));      
    });
  }

  goHome() {
    this.router.navigate(['/furnitures/dashboard']);
  }

  editProfile() {
    if (this.currentUser !== this.amazonUserName) {
      this.router.navigate(['/users/edit-profile'])
    }    
  }

  logout() {
    this.amplifyService.auth().signOut()
      .then(() => {
        this.router.navigate(['/auth/login'])
      })
      .catch(err => console.log(err));
  }
}
