import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { UploadFirmwareComponent } from './firmware/upload-firmware/upload-firmware.component';
import { AdminRoutingModule } from './admin-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './admin.component';
import { NavService } from './nav.service';
import { MenuListItemComponent } from './menu/menu-list-item/menu-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTabsetModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FirmwareService } from './firmware/firmware.service';
import { UploadFirmwareModalComponent } from './firmware/upload-firmware/upload-firmware-modal/upload-firmware-modal.component';
import { AppComponent } from '../app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DistributionDetailsModalComponent } from './firmware/upload-firmware/distribution-details-modal/distribution-details-modal.component';
import { AdminFurnitureComponent } from './furnitures/admin-furniture/admin-furniture.component';
import { AdminProfileComponent } from './profile/admin-profile/admin-profile.component';
import { AdminLogoutComponent } from './admin-logout/admin-logout.component';
import { UserListComponent } from './user/list/user-list.component';
import { UserService } from './user/user.service';
import { UserDetailsModalComponent } from './user/list/user-details/user-details-modal.component';


const LAYOUT_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  LayoutModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule
];

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    TranslateModule,
    NgbTabsetModule,
    NgbModalModule,
    NgbDropdownModule,
    ...LAYOUT_MODULES,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AdminComponent, 
    MenuComponent, 
    MenuListItemComponent, 
    UploadFirmwareComponent, 
    UploadFirmwareModalComponent, 
    DistributionDetailsModalComponent, 
    AdminFurnitureComponent, 
    AdminProfileComponent, 
    AdminLogoutComponent,
    UserListComponent,
    UserDetailsModalComponent
  ],
  providers: [
    NavService,
    FirmwareService,
    UserService,
    AppComponent
  ],
  entryComponents: [UploadFirmwareModalComponent, DistributionDetailsModalComponent, UserDetailsModalComponent]
})
export class AdminModule { }
