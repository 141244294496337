import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private modal: NgbActiveModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  private errors = {
    verificationCode: true,
    password: true,
    confirmPassword: true
  }

  @Input() private username: string;

  private verificationCode: string;
  private password: string; 
  private confirmPassword: string;


  resetPassword() {
    if (!this.errors.verificationCode && !this.errors.password && !this.errors.confirmPassword) {
      this.amplifyService.auth().forgotPasswordSubmit(this.username, this.verificationCode, this.password)
        .then(() => {
          this.modal.close();
          this.amplifyService.auth().signIn(this.username, this.password)
            .then(() => {              
              this.router.navigate(['/furnitures/dashboard'])
            })
            .catch(() => {
              this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.generic"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
                timeOut: 2500
              });
            })
        })
        .catch((err) => {
          this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.verification-code"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
            timeOut: 2500
          });
        });
    }
  }


  validateField(field: string, value: string) {
    if (field === 'verificationCode') {
      if (value !== "") {
        this.setElementBackground(field);
      } else {
        this.unsetElementBackground(field);
      }
    } else if (field === 'password') {
      // Check confirm password if present
      if (this.confirmPassword) {
        if (this.confirmPassword === this.password) {
          this.setElementBackground('confirmPassword');
        } else {
          this.unsetElementBackground('confirmPassword');
        }
      }

      if (this.checkPasswordPolicy(value)) {
        this.setElementBackground(field)
      } else {
        this.unsetElementBackground(field);
        this.unsetElementBackground('confirmPassword');
      }
    } else if (field === 'confirmPassword') {
      if (this.password && value === this.password && this.checkPasswordPolicy(value)) {
        this.setElementBackground(field);
      } else {
        this.unsetElementBackground(field);        
      }
    } 
  }

  setElementBackground(element) {
    document.getElementById(element).style.background = "url('/assets/images/ic-checked-font-awesome.png') 95% 0% no-repeat";
    document.getElementById(element).style.backgroundSize = "20px";

    this.errors[element] = false;
  }

  unsetElementBackground(element) {
    document.getElementById(element).style.background = "";
    this.errors[element] = true;
  }

  checkPasswordPolicy(password: string) {
    if (password.length >= 8
      && password.match(/[A-Z]/)
      && password.match(/[a-z]/)
      && password.match(/[0-9]/)
      && password.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/)) {
        return true;
      }
      return false;
  }
}
