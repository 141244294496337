import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { CanActivate, Router, ActivatedRoute } from "@angular/router";
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js";

@Injectable()
export class RoleAuthGuard implements CanActivate {
  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {}

  canActivate() {
    return this.amplifyService.auth().currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        return this.amplifyService.auth().userSession(user)
          .then((session: CognitoUserSession) => {
            let token = session.getIdToken().decodePayload();
            if (token['cognito:groups'] && token['cognito:groups'].indexOf('admin') !== -1) {
              return true;
            } else {
              this.router.navigate(['/furnitures/dashboard']);
              return false;
            }            
          }); 
      })
      .catch((err) => {
        console.log(err);
        this.router.navigate(['/auth/login']);

        return false;
      })
  }
}