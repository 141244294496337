import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';


import { FurnitureListComponent } from "./furniture-list/furniture-list.component";
import { RegisterComponent } from './register/register.component';
import { FurnitureDetailComponent } from './furniture-detail/furniture-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FurnituresRoutingModule } from './/furnitures-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import { FurnitureDetailTagComponent } from './furniture-detail-tag/furniture-detail-tag.component';
import { MessageService } from './message.service';
import { FurnitureDeleteComponent } from './furniture-delete/furniture-delete.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HeadersModule } from '../headers/headers.module';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FurnituresRoutingModule,
    TranslateModule,
    NgbTabsetModule,
    NgbModalModule,
    NgxSpinnerModule,
    HeadersModule
  ],
  declarations: [
    FurnitureListComponent,
    RegisterComponent,
    FurnitureDetailComponent,
    FurnitureDetailTagComponent,
    DashboardComponent,
    FurnitureDeleteComponent
  ],
  providers: [MessageService],
  entryComponents: [FurnitureDeleteComponent]
})
export class FurnituresModule { }
