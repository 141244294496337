import { OnInit, Component, Input, HostBinding } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { Router } from '@angular/router';
import { NavItem } from "../nav-item";
import { NavService } from "../../nav.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  
  private expanded: boolean;
  @HostBinding('attr.aria-expanded') private ariaExpanded = this.expanded;
  @Input() private item: NavItem;
  @Input() private depth: number;

  constructor(
    private navService: NavService,
    private translateService: TranslateService,
    private router: Router
    // private router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      // this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

}