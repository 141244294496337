import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyEnComponent } from './privacy-en/privacy-en.component';
import { PrivacyDeComponent } from './privacy-de/privacy-de.component';
import { PrivacyItComponent } from './privacy-it/privacy-it.component';

const routes: Routes = [
  { path: 'privacy/de', component: PrivacyDeComponent },
  { path: 'privacy/en', component: PrivacyEnComponent },
  { path: 'privacy/it', component: PrivacyItComponent },
  { path: 'privacy', redirectTo: 'privacy/en' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyRoutingModule { }
