import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { FurnitureService } from '../furniture.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-furniture-tag',
  templateUrl: './furniture-detail-tag.component.html',
  styleUrls: ['./furniture-detail-tag.component.css']
})
export class FurnitureDetailTagComponent implements OnInit {

  private _rooms = [`LIVING ROOM`, 'BEDROOM', 'HALL', 'DINING ROOM'];//TODO su env
  private _colors = ['RED', 'YELLOW', 'BLUE', 'GREEN', 'WHITE'];

  tagnumber = 0;

  private tagName: string;

  @Input() type: string;

  constructor(
    private furnitureService: FurnitureService, //TODO valutare provider !!!
    private router: Router,
    private modal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  add(tag) {
    if (this.type == 'number') {
      this.modal.close({type: this.type, tag: this.tagnumber});
    } else if (this.type == 'name') {
      if (this.tagName) {
        this.modal.close({type: this.type, tag: this.tagName.toUpperCase()});
      }      
    } else {
      this.modal.close({type: this.type, tag: tag});
    }      
  }



}
