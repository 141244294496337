import { Component, OnInit, Input } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-confirm',
  templateUrl: './sign-up-confirm.component.html',
  styleUrls: ['./sign-up-confirm.component.css']
})
export class SignUpConfirmComponent implements OnInit {

  constructor(
    private amplifyService: AmplifyService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    public modal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit() {
  }

  @Input() user: CognitoUser;

  @Input() private name: string;
  @Input() private surname: string;
  @Input() private password: string;

  private verificationCode: string;

  verifyUser() {
    if (this.verificationCode) {
      this.amplifyService.auth()
        .confirmSignUp(this.user.getUsername(), this.verificationCode)
          .then(() => {
            this.amplifyService.auth().signIn(this.user.getUsername(), this.password)
              .then(user => {
                // Update user attributes if provided
                let attributes: any = {};
                if (this.name) {
                  this.name.trim();
                  attributes.name = this.name;
                }
                if (this.surname) {
                  this.surname.trim();
                  attributes.family_name = this.surname;
                }

                this.amplifyService.auth().updateUserAttributes(this.user, attributes)
                  .then(() => {
                    this.modal.close();
                    this.router.navigate(['/furnitures/dashboard'])
                  })
                  .catch(() => {
                    this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.generic"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
                      timeOut: 2500
                    });
                  })                    
              })
              .catch(() => {
                this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.generic"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
                  timeOut: 2500
                });
              })
          })
          .catch(() => {
            this.toastr.error(this.translateService.instant("auth.sign-up.messages.errors.verification-code"), this.translateService.instant("auth.sign-up.messages.errors.title"), {
              timeOut: 2500
            });
          })
    }
    
  }

}
