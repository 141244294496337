import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { NavService } from '../nav.service';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { NavItem } from './nav-item';
import { MatSidenav } from '@angular/material';
import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/furnitures/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'admin-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  private mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  private isSmallScreen: boolean = false;

  private name: string;
  private surname: string;
  private currentUser: string;
  private role: string = "Admin"; // TODO da modificare con ruolo reale
  private profileImageSrc: any = "../../assets/images/ic-profile_placeholder@2x.png";

  private profileInfo: Subscription;

  constructor(
    private navService: NavService,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private mediaMatcher: MediaMatcher,
    private amplifyService: AmplifyService,
    private messageService: MessageService,
    private router: Router) {
      this.mobileQuery = mediaMatcher.matchMedia('(max-width: 1200px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }

  ngOnInit() {
    this.navService.setSidenav(this.sidenav);
    this.configureSideNav();

    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user) => {
        if (user.username) {
          this.amplifyService.auth().userAttributes(user)
          .then((attributes: CognitoUserAttribute[]) => {
            attributes.forEach((attribute: CognitoUserAttribute) => {
              if (attribute.getName() === "name") {
                this.name = attribute.getValue();
              } else if (attribute.getName() === "family_name") {
                this.surname = attribute.getValue();
              } else if (attribute.getName() === "picture") {
                this.amplifyService.storage().get(attribute.getValue())
                  .then((res) => {
                    this.profileImageSrc = res;
                  })
                  .catch(err => console.log(err))                
              } 
            });
            if (this.name && this.surname) {
              this.currentUser = this.name + " " + this.surname;
            } else {
              this.currentUser = user.username;              
            }
          });
        } else { // logged in with Amazon
          this.navItems.forEach(navItem => {
            if (navItem.title === this.translateService.instant('admin.menu.profile')) {
              navItem.route = "/admin/firmware/update-firmware"; // remove reference to edit-user-profile for a user logged in with Amazon
            }
          })
        }     
      });
    
    this.profileInfo = this.messageService.getHeaderMessage().subscribe((user) => {
      this.currentUser = user.name + " " + user.family_name;
      if (user.picture) {
        this.amplifyService.storage().get(user.picture)
          .then((res) => {
            this.profileImageSrc = res;
          })
          .catch(err => console.log(err))
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  // Menu
  @ViewChild('sidenav') sidenav: MatSidenav;  
  private navItems: NavItem[] = [
    {
      title: this.translateService.instant('admin.menu.furnitures'),
      iconName: '',
      route: '/admin/furnitures',
      children: [],
    },
    {
      title: this.translateService.instant('admin.menu.firmware-update'),
      iconName: '',
      route: '/admin/update-firmware',
      children: [],
    },
    {
      title: this.translateService.instant('admin.menu.profile'),
      iconName: '',
      route: '/admin/edit-profile',
      children: [],
    },
    {
      title: this.translateService.instant('admin.menu.user-list'),
      iconName: '',
      route: '/admin/user-list',
      children: [],
    },
    {
      title: this.translateService.instant('admin.menu.logout'),
      iconName: '',
      route: '/admin/logout',
      children: [],
    }
  ];


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureSideNav();
  }

  configureSideNav() {
    this.isSmallScreen = window.innerWidth < 1200 ? true : false;
    if (!this.isSmallScreen) {
      this.sidenav.mode = "side";
      this.sidenav.opened = true;
    } else {
      this.sidenav.mode = 'over';
      this.sidenav.opened = false;
      // this.sidenav.opened = false;
    }
  }

  editProfile() {
    this.amplifyService.auth().currentAuthenticatedUser()
      .then((user) => {
        if (user.username) {
          this.router.navigate(['/admin/edit-profile']);
        }
      })
    
  }
}
