import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';

import { FurnitureService } from '../furniture.service';
import { Furniture } from '../furniture';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FurnitureDetailTagComponent } from '../furniture-detail-tag/furniture-detail-tag.component';
import { AmplifyService } from 'aws-amplify-angular';
import { MessageService } from '../message.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FurnitureDeleteComponent } from '../furniture-delete/furniture-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-furniture-detail',
  templateUrl: './furniture-detail.component.html',
  styleUrls: ['./furniture-detail.component.css']
})
export class FurnitureDetailComponent implements OnInit {

  private _rooms: String[] = [`LIVING ROOM`, 'BEDROOM', 'HALL', 'DINING ROOM'];//TODO su env
  private _colors: String[] = ['RED', 'YELLOW', 'BLUE', 'GREEN', 'WHITE'];

  @Input() furniture = new Furniture('', '', '', '', '', '', {}, []);
  @Input() viewType: string = '';
  @Input() update: boolean = false;

  rooms = [];
  colors = [];
  numbers = [];

  // CSS class on hover (view)
  private onHoverClassNumber: string = "show-on-hover-number-span-view";
  private onHoverClassColor: string = "show-on-hover-color-span-view";
  private onHoverClassRoom: string = "show-on-hover-room-span-view";
  private onHoverClassName: string = "show-on-hover-name-span-view";

  private modalEditIcon: string = "../../../assets/images/ic-edit@2x.png";
  private modalDeleteIcon: string = "../../../assets/images/ic-delete@2x.png";

  // CSS class tag text
  private tagTextClass: string = "tag-text-view";

  constructor(
    private furnitureService: FurnitureService, //TODO valutare provider !!!
    private router: Router,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private amplifyService: AmplifyService,
    private messageService: MessageService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    // Adding tags if exists
    if (this.furniture.tags) {
      this.furniture.tags.forEach((tag) => {
        if (typeof tag === 'number') {
          this.numbers.push(tag);
        } else if (this._rooms.indexOf(tag) !== -1) {
          this.rooms.push(tag);
        } else if (this._colors.indexOf(tag) !== -1) {
          this.colors.push(tag);
        } 
      });
    }
    

    // CSS class on hover (edit & insert) & tag text
    if (this.viewType !== 'VIEW') {
      this.onHoverClassNumber = "show-on-hover-number-span";
      this.onHoverClassColor = "show-on-hover-color-span";
      this.onHoverClassRoom = "show-on-hover-room-span";
      this.onHoverClassName = "show-on-hover-name-span";

      this.tagTextClass = "tag-text";
    }
  }

  addTag(type) {
    const ref = this.modalService.open(FurnitureDetailTagComponent, { centered: true, size: "sm" });
    ref.componentInstance.type = type;
    ref.result.then(res => {
      let t;
      switch (res.type) {
        case 'number':
          t = this.numbers;
          break;
        case 'color':
          t = this.colors
          break;
        case 'room':
          t = this.rooms;
          break;
        default:
          throw new Error(res.type + "unknown type");
      }
      if (t.indexOf(res.tag) == -1)
        t.push(res.tag);
    }).catch(err => { })
  }

  editFurniture(furniture: Furniture) {
    this.modal.close();
    
    const ref = this.modalService.open(FurnitureDetailComponent, { windowClass: 'xlarge-modal', centered: true, backdrop: "static", keyboard: false });
    
    ref.componentInstance.furniture = new Furniture(furniture.type, furniture.unique_id, furniture.brand, furniture.model, furniture.version, furniture.name, furniture.attributes, furniture.tags);
    ref.componentInstance.viewType = 'EDIT';
    ref.componentInstance.update = true;
  }

  deleteFurniture(furniture: Furniture) {
    const ref = this.modalService.open(FurnitureDeleteComponent, { size: "sm", centered: true, backdrop: "static", keyboard: false});
    
    ref.componentInstance.furniture = new Furniture(furniture.type, furniture.unique_id, furniture.brand, furniture.model, furniture.version, furniture.name, furniture.attributes, furniture.tags);
    ref.result
      .then(() =>  { 
        this.modal.close();

        this.messageService.sendFurnitureMessage(furniture);
      })
      .catch(() => {});
  }

  removeTag(type, tag) {
    switch (type) {
      case "number": {
        this.numbers.splice(this.numbers.indexOf(tag), 1);
        break;
      }
      case "color": {
        this.colors.splice(this.colors.indexOf(tag), 1);
        break;
      }
      case "room": {
        this.rooms.splice(this.rooms.indexOf(tag), 1);
        break;
      }
    }
  }

  onContinue() {
    this.spinner.show();

    let fs = this.furnitureService;
    this.furniture.tags = this.numbers.concat(this.rooms, this.colors);
    this.amplifyService.auth().currentAuthenticatedUser() //TODO centralizzare
      .then(res => {
        let user;
        if (res.username)
          user = res.username;
        else
          user = res.name;
        if (!this.furniture.name || this.furniture.name.trim() === '') {
          this.toastr.error(this.translateService.instant("furnitures.detail.messages.error.body"), this.translateService.instant("furnitures.detail.messages.error.title"), {
            positionClass: "toast-top-center",
            timeOut: 2500
          });
        } else {
          this.modal.close();
          fs.upsertFurniture({ user: user, furniture: this.furniture, update: this.update }).subscribe((res: Promise<any>) => {
            res
              .then((result) => {
                this.spinner.hide();

                if (result && result.errorMessage) {
                  this.toastr.error(result.errorMessage, this.translateService.instant("furnitures.detail.messages.error.title"), {
                    timeOut: 2500
                  });
                } else {
                  this.messageService.sendFurnitureMessage(this.furniture);
                }
              })
              .catch((error) => {
                this.toastr.error(this.translateService.instant("furnitures.detail.messages.error.code"), this.translateService.instant("furnitures.detail.messages.error.title"), {
                  timeOut: 2500
                });
                this.spinner.hide();
              })
          });
        }
      })
      .catch(err => {
        console.log(err);
      });


  }
}
