import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './furnitures/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: '/furnitures/dashboard', pathMatch: 'full' },
  { path: '**', component: DashboardComponent, canActivate: [AuthGuard],} //TODO permette l'accesso con queryParam su index.html
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
