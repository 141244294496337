import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FurnitureDetailComponent } from './furniture-detail/furniture-detail.component';
import { RegisterComponent } from './register/register.component';
import { FurnitureListComponent } from './furniture-list/furniture-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '../auth/auth-guard';
import { FurnitureDetailTagComponent } from './furniture-detail-tag/furniture-detail-tag.component';

const furnituresRoutes: Routes = [
  { path: 'furnitures/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'furnitures/register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'furnitures/furniture/:id', component: FurnitureDetailComponent, canActivate: [AuthGuard] },
  { path: 'furnitures/furniture/tag', component: FurnitureDetailTagComponent, canActivate: [AuthGuard] }, //serve?
  { path: 'furnitures', component: FurnitureListComponent, canActivate: [AuthGuard] },
  //{ path: '', component: FurnitureListComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(furnituresRoutes)
  ],
  exports: [RouterModule]
})
export class FurnituresRoutingModule { }
