import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadFirmwareComponent } from './firmware/upload-firmware/upload-firmware.component';
import { AdminComponent } from './admin.component';
import { RoleAuthGuard } from '../auth/role-auth-guard';
import { AdminFurnitureComponent } from './furnitures/admin-furniture/admin-furniture.component';
import { AdminProfileComponent } from './profile/admin-profile/admin-profile.component';
import { AdminLogoutComponent } from './admin-logout/admin-logout.component';
import { UserListComponent } from './user/list/user-list.component';

const adminRoutes: Routes = [ 
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "furnitures", component: AdminFurnitureComponent, canActivate: [RoleAuthGuard]
      },
      {
        path: "update-firmware", component: UploadFirmwareComponent, canActivate: [RoleAuthGuard]
      },
      {
        path: "edit-profile", component: AdminProfileComponent, canActivate: [RoleAuthGuard]
      },
      {
        path: "user-list", component: UserListComponent, canActivate: [RoleAuthGuard]
      },
      {
        path: "logout", component: AdminLogoutComponent, canActivate: [RoleAuthGuard]
      },
      {
        path: "", pathMatch: 'full', redirectTo: "furnitures"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
