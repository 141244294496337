import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
//import { FormsModule }   from '@angular/forms';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FurnituresModule } from './furnitures/furnitures.module';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/auth-guard';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersModule } from './users/users.module';
import { HeadersModule } from './headers/headers.module';
import { AdminModule } from './admin/admin.module';
import { RoleAuthGuard } from './auth/role-auth-guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrivacyModule } from './privacy/privacy.module';
import { AppService } from './app.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    //  FormsModule,
    AmplifyAngularModule,
    AuthModule,
    AdminModule,
    PrivacyModule,
    FurnituresModule,
    UsersModule,
    HeadersModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [AmplifyService, AuthGuard, RoleAuthGuard, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }