export class Furniture {
  constructor(
    public type: string,
    public unique_id: string,
    public brand: string,
    public model: string,
    public version: string,
    public name: string,
    public attributes: object,
    public tags: String[]) { }
}